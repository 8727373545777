import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import { SeoType, EventType } from '@/types.d'
import moment from 'moment'
import LoadMore from '@/components/LoadMore'

interface EventsData {
  data: {
    page: {
      title: string
      uri: string
      seo: SeoType
      image: any
    }
    events: {
      nodes: Array<EventType>
    }
    menu?: any
    quickmenu?: any
  }
}

const EventsTemplate = ({ data }: EventsData) => {
  // Page data
  const { page, events } = data

  const { menu, quickmenu } = data

  const [type, setType] = useState('Tulevat tapahtumat')

  const heroContent = {
    image: page?.image,
    disableBackButton: true,
  }

  const dateToday = moment().format('YYYY.MM.DD')
  const upcomingEvents: any = []
  const pasteEventsReversed: any = []

  events.nodes.map((event: any) => {
    const eventdateinformat = moment(event.eventenddate, 'D.M.YYYY').format('YYYY.MM.DD')
    const eventStartRev = moment(event.eventdate, 'D.M.YYYY').format('YYYY.MM.DD')

    event.dateRev = eventdateinformat
    event.dateStartRev = eventStartRev

    if (eventdateinformat >= dateToday) {
      upcomingEvents.push(event)
    } else {
      pasteEventsReversed.push(event)
    }
  })

  const setTypeFilter = (currentType: string) => {
    setType(currentType)
  }

  const categories = [
    { id: 1, name: 'Tulevat tapahtumat' },
    { id: 2, name: 'Menneet tapahtumat' },
  ]

  const pastEvents: any = pasteEventsReversed.sort((a: any, b: any) => {
    const date1 = new Date(b.dateStartRev)
    const date2 = new Date(a.dateStartRev)
    return date1.getTime() - date2.getTime()
  })

  const upcomingEventsFinal: any = upcomingEvents.sort((a: any, b: any) => {
    const date1 = new Date(b.dateStartRev)
    const date2 = new Date(a.dateStartRev)
    return date2.getTime() - date1.getTime()
  })

  const showEvents =
    type === 'Tulevat tapahtumat'
      ? upcomingEventsFinal
      : type === 'Menneet tapahtumat'
      ? pastEvents
      : upcomingEventsFinal

  const eventkey = new Date()
  const loadmoreKey = eventkey.getTime()

  return (
    <Layout
      title={page?.title}
      hero={heroContent}
      nav={menu}
      quickNav={quickmenu}
      uri={page?.uri}
      seo={page?.seo}
      useBreadCrump={true}
    >
      <div className="flex md:flex-row flex-col mb-0 items-start md:items-center">
        <div>
          {categories.map(cat => {
            return (
              <button
                className={`hover:text-primary focus:text-primary y-2 mr-10 font-FuturaBol ${
                  type === cat.name ? 'text-primary' : ''
                }`}
                onClick={() => setTypeFilter(`${cat.name ?? ''}`)}
                key={cat.id}
              >
                {cat.name}
              </button>
            )
          })}
        </div>
      </div>
      <LoadMore key={loadmoreKey} load={6} posts={showEvents} postType="event" />
    </Layout>
  )
}

export const PageQuery = graphql`
  query EventsPageById($id: String) {
    page: wpPage(id: { eq: $id }) {
      id
      title
      uri
      heroFilter: gwShowPostHeaderFilter
      ...seo
      ...featuredHeroImage
    }
    events: allWpGwEvent(sort: { fields: gwEventsDate, order: ASC }) {
      nodes {
        eventdate: gwEventsDate
        eventenddate: gwEventsDateEnd
        eventlocation: gwEventsLocation
        eventtime: gwEventsTime
        content
        uri
        title
        id
      }
    }
    menu: wpMenu(locations: { eq: MAIN_MENU }) {
      ...navigation
    }
    quickmenu: wpMenu(locations: { eq: QUICK_NAV }) {
      ...navigation
    }
  }
`

export default EventsTemplate
