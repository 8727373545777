import React, { useState, useEffect } from 'react'
import FeaturedItem from '@/components/FeaturedItem'
import Event from '@/components/Event'
import Excerpt from '@/components/Excerpt'

interface LoadMore {
  posts: any
  className?: string
  classNameButton?: string
  total?: number
  load?: number
  buttonText?: string
  postType?: string
  reduceByone?: boolean
  readMoreString?: string
}

const LoadMore = ({
  posts,
  load = 6,
  reduceByone = false,
  className = '',
  postType = 'post',
  classNameButton = '',
  buttonText = 'Katso lisää',
}: LoadMore) => {
  const [reduce, setReduce] = useState(0)
  const numberPosts = load - reduce || 4
  const [paginatedPosts, setPaginatedPosts] = useState([...posts.slice(0, numberPosts)])
  const [loadMore, setLoadMore] = useState(false)

  const [hasMore, setHasMore] = useState(posts.length > numberPosts)

  const handleLoadMore = () => {
    setLoadMore(true)
  }

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = paginatedPosts.length
      const isMore = currentLength < posts.length
      const nextResults = isMore ? posts.slice(currentLength, currentLength + numberPosts) : []
      setPaginatedPosts([...paginatedPosts, ...nextResults])
      setLoadMore(false)
    }
    if (reduceByone) {
      setReduce(1)
    }
  }, [loadMore, hasMore, numberPosts, paginatedPosts, posts, reduceByone])

  useEffect(() => {
    const isMore = paginatedPosts.length < posts.length
    setHasMore(isMore)
  }, [paginatedPosts, posts.length])

  return (
    <>
      <div className={`${className}`}>
        {postType === 'post' &&
          paginatedPosts?.map((post: any) => {
            const alt: string = post?.featuredImage?.node?.altText || 'post image'
            const shortTitle = post.title.length > 50 ? post.title.substr(0, 50 - 1) + '...' : post.title
            return (
              <FeaturedItem
                key={post.id}
                to={post.uri}
                background={post?.img?.node}
                date={post.date}
                featuredType={post?.terms?.nodes}
                content={shortTitle}
                alt={alt ? alt : 'image'}
              />
            )
          })}

        {postType === 'event' &&
          paginatedPosts?.map((post: any) => {
            return (
              <Event
                key={post?.id}
                heading={post?.title}
                date={post?.eventdate}
                enddate={post?.eventenddate}
                time={post?.eventtime}
                place={post?.eventlocation}
                className="my-20"
                readMore={post?.uri}
              >
                <Excerpt content={post?.content} number={400} removeTables={true} />
              </Event>
            )
          })}
      </div>

      <div className={`${classNameButton}`}>
        {hasMore ? (
          <div className="flex items-center justify-center group mt-10 mb-20">
            <button className={`px-9 leading-6 pt-3 pb-2 bg-white shadow-xl`} onClick={handleLoadMore}>
              {buttonText}
            </button>
          </div>
        ) : (
          <div className="flex items-center justify-center group mt-10 mb-20">
            <p className="">Ei enempää julkaisuja</p>
          </div>
        )}
      </div>
    </>
  )
}
export default LoadMore
